import React from "react";
import {
    useCalendlyEventListener,
    InlineWidget,
    EventScheduledEvent
} from "react-calendly";

interface CalendlyWidgetProps {
    url: string;
    name?: string;
    email?: string;
    styles?: React.CSSProperties | undefined;
    onProfilePageViewed?: () => void;
    onDateAndTimeSelected?: () => void;
    onEventTypeViewed?: () => void;
    onEventScheduled?: (e: EventScheduledEvent) => void;
    pageSettings?: any;
}

const CalendlyWidget = (props: CalendlyWidgetProps): JSX.Element => {
    useCalendlyEventListener({
        onProfilePageViewed: props.onProfilePageViewed,
        onDateAndTimeSelected: props.onDateAndTimeSelected,
        onEventTypeViewed: props.onEventTypeViewed,
        onEventScheduled: props.onEventScheduled
    });

    return (
        <InlineWidget
            url={props.url}
            styles={props.styles}
            prefill={{
                name: props.name,
                email: props.email
            }}
            pageSettings={props.pageSettings}
        />
    );
};

export default CalendlyWidget;
