import React from "react";
import { DateTime } from "luxon";
import { EventScheduledEvent } from "react-calendly";
import {
    EventDateTimeSelected,
    ScheduledOnboardingWebinar
} from "analytics/onboarding/GettingStartedAnalytics";
import CalendlyWidget from "components/CalendlyWidget";
import { URL_CALENDLY_SCHEDULING } from "constants/urls";
import { useAuth } from "hooks/use-auth";
import { TaskWidget } from "./types";
import { get12HourTime } from "utils/date";

export const SCHEDULE_KICKOFF_TASK_ID = "schedule-kickoff";

const calendlyStyles: React.CSSProperties = {
    width: "100%",
    height: "600px",
    margin: "0 auto",
    borderRadius: "8px",
    overflow: "hidden"
};

const extractLastPathFromUrl = (url: string) =>
    url.substring(url.lastIndexOf("/") + 1);

const formatCallText = (
    startTime: DateTime | undefined,
    endTime: DateTime | undefined
): string =>
    !startTime || !endTime
        ? "You have completed the kickoff call."
        : `Your Advisor kickoff call ${
              startTime.diffNow().milliseconds > 0 ? "is" : "was"
          } scheduled for ${get12HourTime(startTime)} - ${get12HourTime(
              endTime
          )}, ${startTime.toFormat("DDDD")}`;

/** A self-contained calendar widget to schedule a kickoff call in the get started guide */
export const KickoffCallScheduler: TaskWidget = ({
    completed,
    markAsDone,
    metadata
}) => {
    const { userProfile } = useAuth();
    const { email, firstName, lastName, personalEmail } = userProfile;
    const userAnalyticsBlock = {
        email,
        first_name: firstName,
        last_name: lastName
    };

    if (!completed) {
        const onEventScheduled = async (e: EventScheduledEvent) => {
            const { event, invitee } = e.data.payload;
            ScheduledOnboardingWebinar({
                ...userAnalyticsBlock,
                source: "calendly",
                invitation_id: event.uri
            });

            await markAsDone({
                invitee_id: extractLastPathFromUrl(invitee.uri),
                event_id: extractLastPathFromUrl(event.uri)
            });
        };

        return (
            <CalendlyWidget
                url={URL_CALENDLY_SCHEDULING}
                name={`${firstName} ${lastName}`}
                email={personalEmail}
                styles={calendlyStyles}
                pageSettings={{ hideEventTypeDetails: true }}
                onEventScheduled={onEventScheduled}
                onDateAndTimeSelected={() =>
                    EventDateTimeSelected(userAnalyticsBlock)
                }
            />
        );
    }
    const startTime = metadata?.start_time
        ? DateTime.fromISO(metadata.start_time)
        : undefined;
    const endTime = metadata?.end_time
        ? DateTime.fromISO(metadata.end_time)
        : undefined;

    return (
        <span className="text-secondaryDark">
            {formatCallText(startTime, endTime)}
        </span>
    );
};
